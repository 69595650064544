/* eslint-disable react/no-danger */
import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import { FeaturesSlider } from '@latitude/features-slider';
import { Link } from '@latitude/link';
import { Hero } from '@latitude/hero';
import { Box } from '@latitude/box';
import Layout from '../../components/layout';
import {
  BREAKPOINT,
  COLOR,
  FONT_WEIGHT,
  ALIGN,
  MARGIN
} from '@latitude/core/utils/constants';
import { FeatureTiles } from '@latitude/feature-tiles';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { Heading4 } from '@latitude/heading';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Accordion } from '@latitude/accordion';
import { HeroText } from '@/components/HeroBranded/HeroBranded';
import Section from '@latitude/section';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '../../data/pages/scams-hub.json';
import Promo, { PromoSection } from '../../components/Promo/Promo';
import heroImage from './images/scams-hub-hero.webp';
import newsAlert from './images/news-alerts-img-tile.webp';
import symbolGreater from './images/greater-than-icon.webp';
import scamwatchLogo from '../../pages/scams-hub/images/scamwatch-logo.svg';
import acccLogo from '../../pages/scams-hub/images/accc-logo.svg';
import moneySmartLogo from '../../pages/scams-hub/images/moneysmart-logo.svg';
import { Text } from '@latitude/text';

//const cardsHeading = 'Check out the other Latitude credit cards';
const applyUrl = `mailto:scams@latitudefinancial.com`;
const FREQUENTLY_ASKED_QUESTIONS = [
  {
    q: 'What is a scam?',
    a: (
      <>
        <p>
          A scam is a deceptive scheme or trick used to cheat someone out of
          something, typically money. Scammers use various methods, such as fake
          emails, phone calls, websites, and social media profiles, to deceive
          unsuspecting victims.
        </p>
      </>
    )
  },
  {
    q: 'Common Types of Scams',
    a: (
      <>
        <p>
          <p>
            <b>Phishing:</b> Fake emails/messages requesting personal or
            financial info.
          </p>{' '}
          <p>
            <b>Phone Scams:</b> Impersonators seeking personal info or payments
            over the phone.
          </p>
          <p>
            <b>Investment Scams:</b> Promises of high returns with fake
            opportunities.
          </p>
          <p>
            <b>Lottery/Prize Scams:</b> Claims of winnings requiring payment or
            personal details.
          </p>
          <p>
            <b>Tech Support Scams:</b> Fake tech support gaining computer access
            to steal info or money.
          </p>
          <p>
            <b>Romance Scams:</b> Impersonators building relationships with
            their victims online then ask for financial aid or a gift.
          </p>
        </p>
      </>
    )
  },
  {
    q: 'How to Spot a Scam',
    a: (
      <>
        <p>
          Be wary of unsolicited contact, offers that seem too good to be true,
          pressure to act quickly, suspicious links or attachments, and requests
          for unusual payment methods like wire transfers or cryptocurrency.
        </p>
      </>
    )
  },
  {
    q: 'Steps to Protect Yourself',
    a: (
      <>
        <p>
          Verify contacts through official channels, use strong and unique
          passwords with two-factor authentication, monitor financial statements
          for unauthorised transactions, stay informed about scams, and never
          share one-time passcodes or credit card details.
        </p>
      </>
    )
  },
  {
    q: 'If You Suspect a Scam',
    a: (
      <>
        <p>
          Stop communication with the scammer, avoid sharing personal or
          financial information, report the scam to authorities (Police
          Assistance line: 131 444), notify Latitude to secure your accounts,
          and monitor your accounts for suspicious activity.
        </p>
      </>
    )
  },
  {
    q: 'Reporting Scams',
    a: (
      <>
        <p>
          If you encounter a scam, it is crucial to report it. By doing so, you
          help prevent others from falling victim and assist authorities in
          tracking and shutting down scammers.
          <br />
          <ul>
            <li>Local Authorities: Contact your local police station.</li>
            <li>
              Financial Institutions: Inform Latitude, your bank or credit card
              company.
            </li>
          </ul>
        </p>
      </>
    )
  },
  {
    q: 'Fraud',
    a: (
      <>
        <p>
          Credit card fraud occurs when your card details are stolen, often
          through skimmers. Monitor your accounts regularly, report suspicious
          activity, and protect your information to minimise fraud risks.
        </p>
      </>
    )
  },
  {
    q: 'Additional Resources',
    a: (
      <>
        <div
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
        >
          <span style={{ marginRight: '100px', textAlign: 'center' }}>
            <img
              src={scamwatchLogo}
              alt="Scamwatch Logo"
              style={{ display: 'block', marginBottom: '5px' }}
            ></img>
            <a
              href="https://www.scamwatch.gov.au/"
              style={{ textDecoration: 'none', color: 'blue' }}
            >
              https://www.scamwatch.gov.au/
            </a>
          </span>
          <span style={{ marginRight: '100px', textAlign: 'center' }}>
            <img
              src={acccLogo}
              alt="ACCC Logo"
              style={{ display: 'block', marginBottom: '5px' }}
            ></img>
            <a
              href="https://www.accc.gov.au/"
              style={{ textDecoration: 'none', color: 'blue' }}
            >
              https://www.accc.gov.au/
            </a>
          </span>
          <span style={{ marginRight: '100px', textAlign: 'center' }}>
            <img
              src={moneySmartLogo}
              alt="MoneySmart Logo"
              style={{ display: 'block', marginBottom: '5px' }}
            ></img>
            <a
              href="https://moneysmart.gov.au"
              style={{ textDecoration: 'none', color: 'blue' }}
            >
              https://moneysmart.gov.au
            </a>
          </span>
        </div>
      </>
    )
  }
];

const TeamData = [
  {
    icon: require('./images/frame-3602.webp').default,
    title: 'Suspect suspicious activity?',
    slug: 'Suspect-suspicious-activity',
    content: (
      <>
        <p>
          Our Customer Service Team is here 24/7. <br />
          <br />
          Within Australia call: <a href="tel:1300 630 977">1300 630 977</a>
          <br />
          <br />
          When overseas call:{' '}
          <a href="tel:+61 1300 630 977">+61 1300 630 977</a>,
        </p>
      </>
    )
  },
  {
    icon: require('./images/frame-3603.webp').default,
    title: 'Received a Latitude branded scam',
    slug: 'Received-Latitude-branded-scam',
    content: (
      <>
        <p>
          If you believe you've been sent a fake email or SMS claiming to be
          from Latitude, report it to us at{' '}
          <a href="mailto:scams@latitudefinancial.com">
            scams@latitudefinancial.com
          </a>{' '}
          and then delete it.
          <br />
          <br />
          Please note that while we monitor this inbox, we aren't able to
          respond to individual emails. You'll receive an automated response to
          let you know that we are looking into it.
        </p>
      </>
    )
  },
  {
    icon: require('./images/frame-3604.webp').default,
    title: 'Responded to a scam? ',
    slug: 'Responded-scam?',
    content: (
      <>
        <p>
          If you've responded to or actioned a suspicious email or message, it's
          possible your security has been compromised.
          <br />
          <br />
          Please{' '}
          <a href="https://latitudefs.zendesk.com/hc/en-au/articles/360009263517-Contact-Us">
            contact us
          </a>
          immediately.
        </p>
      </>
    )
  }
];

const ScamsHubPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'When to contact us ',
      featureCards: PageData.content.scamRedFlags
    }
  ];
  /** - END - */

  const NavhandleCallback = item => {
    if (item.anchor == 'step-up-your-security') {
      window.location.href = '/scams-hub/step-up-your-security.html';
    } else if (item.anchor == 'responsible-disclosure') {
      window.location.href = '/scams-hub/responsible-disclosure.html';
    }
  };

  return (
    <Layout location={props.location}>
      <MobileAppInstallPrompts />
      <main
        className="navigation-spacer"
        css={`
          background-color: ${COLOR.GREY6};
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href={`https://www.latitudefinancial.com.au${PageData.path}`}
          />
          <title>{PageData.metaTitle}</title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <Section
            css={`
              background-color: #0555c8;
              padding: 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                height: 525px;
              }
              @media (max-width: ${BREAKPOINT.MD}) {
                && > .container.section__content {
                  padding: 0;
                }
              }
            `}
          >
            <Hero
              css={`
                && {
                  color: #000;
                }

                h1 {
                  color: #fff;
                  font-size: 45px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 3rem;
                }

                p {
                  color: #fff;
                  font-size: 19px;
                  font-weight: normal;
                }

                svg {
                  min-height: 45px;
                  min-width: 45px;
                }

                .HeroContent {
                  padding: 50px 60px 0 10px;
                  line-height: normal;
                  @media (max-width: ${BREAKPOINT.MD}) {
                    background: transparent;
                    h1,
                    p {
                      color: #fff !important;
                    }
                  }
                }

                [class^='Herostyles__HeroImage-'] img {
                  height: 95%;
                  padding: 50px 60px 0 10px;
                }

                @media (max-width: ${BREAKPOINT.MD}) {
                  h1,
                  .text-left,
                  p,
                  .col-12 {
                    color: #000 !important;
                  }

                  p {
                    font-size: 20px;
                    line-height: 24px;
                  }

                  h1 {
                    font-size: 35px;
                    margin: 10px 0;
                  }

                  .HeroContent {
                    padding: 10px;
                  }

                  .text-center {
                    color: #000 !important;
                  }
                }
              `}
              imageContent={
                <div
                  css={`
                    height: 100%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    @media (min-width: ${BREAKPOINT.LG}) {
                      justify-content: flex-end;
                    }
                  `}
                >
                  <img
                    src={heroImage}
                    alt=""
                    css={`
                      height: 250px;
                      width: auto;
                      @media (min-width: ${BREAKPOINT.MD}) {
                        height: 300px;
                      }
                      @media (min-width: ${BREAKPOINT.LG}) {
                        height: 382px;
                      }
                    `}
                  />
                </div>
              }
              className="page-banner-lfs--bg"
            >
              {
                <div
                  id="hero"
                  css={`
                && {
                  padding: 0 15px;
                  color: #fff:

                  @media (min-width: ${BREAKPOINT.LG}) {
                    padding: 0;
                  }
                }
              `}
                >
                  <h1>Latitude Scams & Security Hub</h1>
                  <HeroText
                    css={`
                      && {
                        color: #fff;
                      }
                    `}
                  >
                    Find all the latest news so you can make informed decisions
                    about protecting your personal and financial information.
                  </HeroText>
                </div>
              }
            </Hero>
          </Section>
        )}
        <div
          className="d-none d-lg-block"
          css={`
            position: relative;
            z-index: 2;
          `}
        >
          <StickyNavigation
            items={PageData.nav}
            ctaHref={applyUrl}
            ctaText="Report a scam"
            offsetElem="[data-sticky-navigation-offset]"
            trackEventData={{
              category: 'cta',
              action: 'quote-link'
            }}
            trackId="sticky-get-started"
            clickEventCallback={NavhandleCallback}
            {...state?.inPageNavData?.[0]}
          />
        </div>
        <Section id="scams-alerts">
          <PromoSection css="background-color: #FFF;">
            <Promo
              title={
                <>
                  <span
                    css={`
                      @media (min-width: ${BREAKPOINT.LG}) {
                        font-size:32px;
                      }
                    }
                  `}
                  >
                    Latest scams and alerts{' '}
                  </span>
                  <br />
                </>
              }
              description={
                <>
                  <span
                    css={`
                      font-weight: ${FONT_WEIGHT.NORMAL};
                      font-size: 18px;
                    `}
                  >
                    The following information is about raising awareness and
                    provides general guidance only. Depending on your
                    circumstances, you may need action measures beyond those
                    presented.
                  </span>
                </>
              }
              frameImage={<img src={newsAlert} alt="Personal Loan Girl" />}
              verticalAlignContent
              frameAlignment="left"
              frameBorderColor="#C31B62"
            />
          </PromoSection>

          <Section
            css={`
              background-color: #fff;
              .lfs-box-section {
                background-color: #fff;
              }
            `}
          >
            <div
              css={`
                div {
                  height: 50px;
                  background-color: #f8f8f8;
                  margin: 5px;
                  padding: 15px 10px;
                  font-size: 18px;
                  font-weight: 600;
                  a {
                    text-decoration: none;
                    border: none;
                    color: black;
                    padding-left: 10px;
                  }

                  img {
                    text-decoration: none;
                  }
                  .greater-symbol {
                    float: right;
                    padding-right: 30px;
                  }
                }
              `}
            >
              <div>
                <a href="/scams-hub/october-december-2024.html">Oct - Dec 2024 </a>
                <a
                  className="greater-symbol"
                  href="/scams-hub/october-december-2024.html"
                >
                  <img src={symbolGreater}></img>
                </a>
              </div>
              <div>
                <a href="/scams-hub/jul-sep-2024.html">Jul - Sep 2024 </a>
                <a
                  className="greater-symbol"
                  href="/scams-hub/jul-sep-2024.html"
                >
                  <img src={symbolGreater}></img>
                </a>
              </div>
              <div>
                <a href="/scams-hub/april-june-2024.html">Apr - June 2024 </a>
                <a
                  className="greater-symbol"
                  href="/scams-hub/april-june-2024.html"
                >
                  <img src={symbolGreater}></img>
                </a>
              </div>
              <div>
                <a href="/scams-hub/jan-march-2024.html">Jan - Mar 2024 </a>
                <a
                  className="greater-symbol"
                  href="/scams-hub/jan-march-2024.html"
                >
                  <img src={symbolGreater}></img>
                </a>
              </div>
            </div>
          </Section>
        </Section>

        <Section
          id="when-to-contact-us"
          css={`
            background-color: #f8f8f8;
            h4 {
              color: #0046aa;
            }
            svg circle {
              fill: #fff;
            }
          `}
        >
          <FeaturesSlider
            id="why-contact-us"
            data={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .featureCards
            }
            className="why-choose pt-4 pt-md-5"
            heading={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            subheading={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .description
            }
          />
        </Section>

        <Section>
          <FeatureTiles
            css={`
              h5,
              p {
                text-align: left;
              }
              [class^='FeatureTiles__StyledFeatureTile-'] {
                padding-bottom: 0;
              }
            `}
            bgColor={COLOR.WHITE}
            useLocalAssets
            heading=""
            tiles={TeamData}
          />
        </Section>

        <Section
          id="scam-prevention"
          css={`
            background-color: #f8f8f8;
            margin: 0 auto;
            @media (min-width: ${BREAKPOINT.LG}) {
              width: 85%;
            }
            .lfs-box-section {
              background-color: #f8f8f8 !important;
            }
            .link-block {
              background-color: #f8f8f8 !important;
            }
            .accordion .bg-white {
              background-color: #f8f8f8 !important;
            }
          `}
        >
          <Box.Section>
            <Heading4
              align={ALIGN.CENTER}
              color={COLOR.BLUE_DEEP}
              paddingBottom={MARGIN.M64}
            >
              Scam Prevention
            </Heading4>
            {/* <AnalyticsLocationProvider location="Scam Prevention">
              <Accordion
                css={`
                  .accordion__item-body {
                    padding: 16px 24px;
                    background-color: #f8f8f8 !important;
                  }
                `}
                titleBgColor="#f8f8f8"
                items={PageData.content.faq}
              />
            </AnalyticsLocationProvider> */}
            <Accordion
              items={FREQUENTLY_ASKED_QUESTIONS.map(
                (item, index, allItems) => ({
                  id: 'faq-item-' + index,
                  title: item.q,
                  content: <Text css={{ padding: '10pt 20pt' }}>{item.a}</Text>
                })
              )}
            />
          </Box.Section>
        </Section>
      </main>
    </Layout>
  );
};

export default ScamsHubPage;
